import React, { useState, useContext } from "react";
import logo from "../../assets/img/logo-512.png";
import { Formik } from "formik";
import { FaPhone } from "react-icons/fa";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import styles from "./Login.module.css";
import { login as loginRequest, checkCode } from "../../api/requests";
import { loginValidator } from "../../helper/validation";
import LoadingCM from "../../components/UI/Loading";
import Modal from "../../components/UI/Modal";
import AuthContext from "../../store/auth-context";
import { convertNum } from "../../helper/validation";

export default function Login() {
  const history = useHistory();
  const authCTX = useContext(AuthContext);
  const [showImg, setShowImg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [phoneNumber, setPhoneNumebr] = useState("");
  const [showSmsModal, setShowSmsModal] = useState(false);

  const checkSmsCode = async (code) => {
    const values = { phoneNumber: phoneNumber, code: convertNum(code) };
    const res = await checkCode(values);

    if (!res.ok) {
      setSmsCode("");
      return toast.error("کد اشتباه وارد شده", {
        position: "top-center",
      });
    }
    authCTX.login(res.data.token);
    history.replace("/home");
  };

  const handleSubmit = async (value, setSubmiting) => {
    setIsLoading(true);
    value.phoneNumber = convertNum(value.phoneNumber);
    const res = await loginRequest(value);
    setPhoneNumebr(value.phoneNumber);
    setIsLoading(false);
    setSubmiting(false);

    if (!res.ok) {
      if (res.data.code === 3)
        return toast.error("ابتدا باید ثبت نام کنید", {
          position: "top-center",
        });
      else
        return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
          position: "top-center",
        });
    }
    setShowSmsModal(true);
  };

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div className={styles.container}>
      <img
        src={logo}
        className={`${styles.img} ${showImg ? styles.hidden : ""} `}
        alt=""
      />
      <Formik
        initialValues={{ phoneNumber: "" }}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
        validationSchema={loginValidator}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <>
            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <div className={styles.inputContainer}>
                <FaPhone size={18} className={styles.icon} />
                <input
                  className={styles.input}
                  name="phoneNumber"
                  onChange={handleChange}
                  value={values.phoneNumber}
                  onFocus={() => setShowImg(true)}
                  onBlur={() => {
                    setFieldTouched("phoneNumber");
                  }}
                />
              </div>

              <button
                className={styles.submitBtn}
                type="submit"
                disabled={isSubmitting}
              >
                ورود
              </button>
            </form>
            <p className="inputErrors">
              {touched.phoneNumber && errors.phoneNumber}
            </p>
          </>
        )}
      </Formik>

      <Modal
        show={showSmsModal}
        title=""
        sms
        onClose={() => setShowSmsModal(false)}
      >
        <p className={styles.smsText}>
          کد تایید به شماره {phoneNumber} ارسال شد
        </p>
        <input
          className={styles.smsInput}
          name="sms"
          inputMode="numeric"
          placeholder="کد را وارد کنید"
          onChange={(e) => {
            if (e.target.value.length === 5) {
              checkSmsCode(e.target.value);
            }
            setSmsCode(e.target.value);
          }}
          value={smsCode}
        />
      </Modal>
    </div>
  );
}
