import React from "react";
import { Modal, Button } from "react-bootstrap";

import styles from "./DeleteModal.module.css";

export default function DeleteModal(props) {
  return (
    <div onClick={props.onClose} style={{backgroundColor:'rebeccapurple'}}>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClick={props.onClose}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Modal.Body className={styles.modalBody}>
            <p>آیا از حذف اطمینان دارید ؟</p>
          </Modal.Body>
          <Modal.Footer className={styles.modalFooter}>
            <Button onClick={props.onClose}>خیر</Button>
            <Button onClick={props.onDelete}>بله</Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}
