import React, { useEffect, useState } from "react";
import imgSrc from "../../assets/img/logo-512.png";

import styles from "./Splash.module.css";

export default function Splash({ renderPageCount }) {
  const [isLoading, setIsLoading] = useState(false);

  let endLoading = new Promise((resolve, reject) => {
    setTimeout(() => resolve(), 5000);
  });

  useEffect(() => {
    endLoading.then(() => {
      setIsLoading(true);
    });
  }, []);

  return (
    <>
      {!isLoading && renderPageCount == 1 && (
        <div className={`${styles.splash} ${isLoading ? styles.animate : ""}`}>
          <div>
            <img src={imgSrc} />
          </div>
          <p>از ۱۳۵۵ تا به امروز</p>
          <div
            className={`${styles.bg} ${isLoading ? styles.animate : ""}`}
          ></div>
          <p>فرشید غزالیان</p>
        </div>
      )}
    </>
  );
}
