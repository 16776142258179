import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import logo from "../assets/img/logo-512.png";
import { HiDotsVertical } from "react-icons/hi";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { SiTelegram } from "react-icons/si";
import { useHistory } from "react-router-dom";
import {
  MdOutlineClose,
  MdOutlinePrivacyTip,
  MdPhoneEnabled,
  MdGroups,
  MdUpgrade,
  MdMenu,
} from "react-icons/md";
import { FaApple } from "react-icons/fa";
import { GrAndroid } from "react-icons/gr";

import img from "../assets/img/canvas.png";
import { ReactComponent as AppleIcon } from "../assets/img/apple-share-icon.svg";
import styles from "./WebHome.module.css";
import CustomLink from "../components/UI/Link";
import Modal from "../components/UI/Modal";
import LoadingCM from "../components/UI/Loading";
import { getConfig as getConfigRequest } from "../api/requests";
import Splash from "../components/UI/Splash";

let counter = 0;

const WebHome = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [showNav, setShowNav] = useState(false);
  const [showContant, setShowContact] = useState(false);
  const [showPrivate, setShowPrivate] = useState(false);
  const [showAbout, setAboutShow] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [config, setConfig] = useState({ aboutUs: "", contactUs: "" });

  if (window.matchMedia("(display-mode: standalone)").matches) {
    history.push("/home");
  }

  const getData = async () => {
    const res = await getConfigRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    setConfig({
      aboutUs: parse(res.data.aboutUs),
      contactUs: parse(res.data.contactUs),
    });
  };

  useEffect(() => {
    getData();

    counter++;
  }, []);

  const handleNav = () => setShowNav((state) => !state);

  const handleClose = (modalName) =>
    modalName === "contact" ? setShowContact(false) : setAboutShow(false);

  const handleShow = (modalName) =>
    modalName === "contact" ? setShowContact(true) : setAboutShow(true);

  const handleHidePrivate = () => setShowPrivate(false);

  const handleShowPrivate = () => setShowPrivate(true);

  const handleUpgrade = () => setShowUpgrade((state) => !state);

  if (isLoading) return <LoadingCM type="bubbles" isWeb />;

  return (
    <Fragment>
      <Splash renderPageCount={counter} />
      <div className={styles.header}>
        <div className={styles.mainlink}>
          <div
            className={styles.navLinks}
            onClick={() => handleShow("contact")}
          >
            <span>تماس با ما</span>
          </div>

          <div className={styles.navLinks} onClick={handleShow}>
            <span>درباره ما</span>
          </div>

          <div className={styles.navLinks} onClick={handleShowPrivate}>
            <span>حریم شخصی</span>
          </div>

          <div className={styles.navLinks} onClick={handleUpgrade}>
            <span>آپدیت به نسخه جدید</span>
          </div>
        </div>

        <div
          className={`
          ${styles.body} ${showNav ? styles.showBackBtn : styles.hideBackBtn}`}
          id="backmenu"
          onClick={handleNav}
        ></div>
        <img src={img} onClick={handleNav} className={styles.btnshow} />
        <div
          className={`
          ${styles.navbar} ${showNav ? styles.showNav : styles.hideNav}`}
        >
          <MdOutlineClose
            size={26}
            className={styles.closeIcon}
            onClick={handleNav}
          />
          <img src={logo} alt="" style={{ marginTop: 20 }} />
          <CustomLink
            normal="true"
            to="#"
            className={styles.links}
            onClick={() => handleShow("contact")}
          >
            <MdPhoneEnabled size={22} className={styles.navIcon} />
            <span>تماس با ما</span>
          </CustomLink>

          <CustomLink
            normal="true"
            to="#"
            className={styles.links}
            onClick={handleShow}
          >
            <MdGroups size={22} className={styles.navIcon} />

            <span>درباره ما</span>
          </CustomLink>

          <CustomLink
            normal="true"
            to="#"
            className={styles.links}
            onClick={handleShowPrivate}
          >
            <MdOutlinePrivacyTip size={22} className={styles.navIcon} />
            <span>حریم شخصی</span>
          </CustomLink>

          <CustomLink
            normal="true"
            to="#"
            className={styles.links}
            onClick={handleUpgrade}
          >
            <MdUpgrade size={22} className={styles.navIcon} />
            <span>آپدیت به نسخه جدید</span>
          </CustomLink>
        </div>

        <Modal show={showAbout} onClose={handleClose} title={"درباره ما"}>
          <span className={styles.parsHtml}>{config.aboutUs}</span>
        </Modal>

        <Modal
          show={showContant}
          onClose={() => handleClose("contact")}
          title={"ارتباط با ما"}
        >
          <span className={styles.parsHtml}>{config.contactUs}</span>
        </Modal>

        <Modal show={showUpgrade} onClose={handleUpgrade} title={"آپدیت"}>
          <div style={{ textAlign: "right", margin: 5 }}>
            <span className={styles.parsHtml}>
              برای آپدیت کردن اپلیکیشن ابتدا برنامه خود را حذف کنید سپس این صفحه
              و تمامی صفحات مربوط به این صفحه را در مرورگر خود ببندید و دوباره
              وارد سایت شوید و اقدام به نصب اپلیکیشن کنید{" "}
            </span>
          </div>
        </Modal>

        <Modal
          show={showPrivate}
          onClose={handleHidePrivate}
          title={"حریم شخصی"}
        >
          <span className={styles.parsHtml}>
            <h3 className={`${styles.private} ${styles.h3}`}>قوانین عمومی:</h3>
            <p className={styles.private}>
              توجه داشته باشید کلیه اصول و رویه‏‌های این اپلیکیشن منطبق با
              قوانین جمهوری اسلامی ایران، قانون تجارت الکترونیک و قانون حمایت از
              حقوق مصرف کننده است و متعاقبا کاربر نیز موظف به رعایت قوانین مرتبط
              با کاربر است. در صورتی که در قوانین مندرج، رویه‏‌ها و سرویس‏‌های
              این اپلیکیشن تغییراتی در آینده ایجاد شود، این اپلیکیشن به روز
              رسانی می شود و شما توافق می‏‌کنید که استفاده مستمر شما از اپلیکیشن
              به معنی پذیرش هرگونه تغییر است.
            </p>
            <h3 className={`${styles.private} ${styles.h3}`}>
              تعریف مشتری یا کاربر:
            </h3>
            <p className={styles.private}>
              مشتری یا کاربر به شخصی گفته می‌شود که با اطلاعات کاربری خود که در
              فرم ثبت نام درج کرده است، به ثبت سفارش یا هرگونه استفاده از خدمات
              اپلیکیشن اتوسرویس فرشید اقدام نماید.
            </p>
            <h3 className={`${styles.private} ${styles.h3}`}>حریم خصوصی:</h3>
            <p className={styles.private}>
              اتوسرویس فرشید اطلاعات تماس شما را به منظور هویت‌شناسی و اطلاع
              رسانی بیشتر و بهتر به شما دریافت می‌کند. به‌علاوه اینکه به اطلاعات
              خصوصی اشخاصى که از خدمات اپلیکیشن استفاده می‏‌کنند، احترام گذاشته
              واز آن محافظت می‏‌کند ومتعهد می‌شود در حد توان از حریم شخصی شما
              دفاع کند و در این راستا، تکنولوژی مورد نیاز برای هرچه
              مطمئن‏‌تروامن‏‌تر شدن استفاده شما از اپلیکیشن را، توسعه دهد. در
              واقع با استفاده از اتوسرویس فرشید، شما رضایت خود را از این سیاست
              نشان می‏‌دهید.
            </p>
          </span>
        </Modal>

        <img src={logo} alt="" />
      </div>
      <div className={styles.container}>
        <img src={logo} alt="" />
        <p>نسخه وب اپلیکیشن اتو سرویس فرشید را نصب کنید</p>
        <span className={styles.upgradeText}>
          ( برای اپدیت اپلیکیشن بر روی دکمه {<MdMenu size={18} />} که در سمت
          راست بالا صفحه قرار دارد کلیک کنید)
        </span>

        <h3 className={styles.h3}>
          {<FaApple size={24} className={styles.mobileIcon} />}کاربران ios
        </h3>

        <p className={styles.phone}>
          1- در نوار پایین گوشی روی دکمه {<AppleIcon />} بزنید.
          <br />
          2- در منوی بازشده گزینه Add to Home Screen را بزنید.
          <br />
          3- در آخر در بالای صفحه دکمه Add را انتخاب کنید.
        </p>
        <h3 className={styles.h3}>
          {<GrAndroid size={24} className={styles.mobileIcon} />} کاربران
          اندروید{" "}
        </h3>
        <p className={styles.phone}>
          1- در نوار بالای گوشی روی دکمه
          <HiDotsVertical fill="white" />
          بزنید.
          <br />
          2- در منوی باز شده گزینه نصب یا Add to Home Screen را بزنید.
          <br />
        </p>
      </div>
      <div className={styles.footer}>
        <h2>تماس با ما</h2>
        <h3 className={styles.h3}>آدرس :</h3>
        <p className={styles.footerText}>
          مازندران، قائمشهر، میدان امام، ابتدای کمربندی شمالی، روبروی بانک رفاه،
          اتوسرویس فرشید
        </p>
        <h3 className={styles.h3}>شماره تماس :</h3>
        <p className={styles.footerText}>01142241028 - 0912636436</p>
        <h3 className={styles.h3}>ما را در شبکه های اجتماعی دنبال کنید:</h3>
        <div className={styles.icon}>
          <div>
            <a
              href="http://www.instagram.com/autoservice_farshid"
              target="_blank"
            >
              <BsInstagram size="20" />
            </a>
          </div>
          <div>
            <a href="https://t.me/auto_servic_farshid" target="_blank">
              <SiTelegram size="20" />
            </a>
          </div>
          <div>
            <a href="https://wa.me/+989126364363" target="_blank">
              <BsWhatsapp size="20" />
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WebHome;
