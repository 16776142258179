import client from './config';

//authorization
const getCodeUrl = '/auth/get_code';
const checkCodeUrl = '/auth/check_code';
export const login = (phoneNumber) => client.post(getCodeUrl, phoneNumber);
export const signUp = (values) => client.post(getCodeUrl, values);//get code
export const checkCode = (values) => client.post(checkCodeUrl, values);//get code

//companies
const companiesUrl = '/company';
export const getCompanies = () => client.get(companiesUrl);
export const getCompany = id => client.get(companiesUrl + id);

//related cars to specefic company
const carUrl = '/car';
export const getRelatedCars = id => client.get(`${carUrl}?company=${id}`)
export const getCar = id => client.get(`${carUrl}/${id}`)

//user
const userInfo = '/user/info';
const userCar = '/user/car';
export const getUserInfo = () => client.get(userInfo);
export const deleteUserCar = id => client.delete(userCar + "/" + id);
export const addUserCar = id => client.post(userCar + "/" + id);

//product
const productsUrl = '/product';
export const getProducts = () => client.get(productsUrl);
export const getProduct = id => client.get(productsUrl + "/" + id);

//news 
const newsUrl = '/post'
export const getAllNews = () => client.get(newsUrl);
export const getNews = id => client.get(newsUrl + "/" + id);

//about-us and contact-us
const configUrl = '/config';
export const getConfig = () => client.get(configUrl);

//services
const servicesUrl = '/history'
export const getLastService = () => client.get(servicesUrl + "/last");
export const getHistoryById = (id) => client.get(`${servicesUrl}?carId=${id}`);

//notes
const notesUrl = '/note';
export const getAllNotes = () => client.get(notesUrl);
export const getNote = (id) => client.get(notesUrl + "/" + id);
export const updateNote = (id,value) => client.put(notesUrl + "/" + id,value);
export const deleteNote = (id) => client.delete(notesUrl + "/" + id);
export const addNote = (values) => client.post(notesUrl ,values);