import React from "react";
import { MdReadMore } from "react-icons/md";

import styles from "./ProductCard.module.css";
import { IMG_BASE_URL } from "../../../constants/main";

function ProductCard(props) {
  return (
    <div className={styles.card}>
      <div className={styles.imgnews}>
        <img src={`${IMG_BASE_URL}/${props.item.imageUrl}`} alt="" />
      </div>
      <div className={styles.imgtitle}>{props.item.description}</div>
        <div className={styles.imgtext}>{props.item.name}</div>
        <MdReadMore  className={styles.detailIcon}/>
    </div>
  );
}

export default ProductCard;
