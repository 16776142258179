
//for bottom tab-bar changing route
export const ROUTE_NAMES = { news: "اخبار", more: "بیشتر", home: "سرویس ها" }

//token
export const PUBLIC_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGwiOnRydWUsInNlY3JldCI6Ik12a0IlMjNMOGFYY3VIc24iLCJpYXQiOjE2MDcxNzg1NTF9.anMJvzKM9boOs1yx5vQcrM5aF7YZT9ITbiJdl1-tGPQ'
export const TOKEN_SECRET = "^Zq8M3d_mE-sG&WYNA^";

export const IMG_BASE_URL = 'https://api.autoservicefarshid.ir';

