import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import styles from "./CarsList.module.css";

import CompanyCard from "../../components/more/car/CompanyCard";
import NotFound from "../../components/UI/NotFound";
import Search from "../../components/UI/Search";
import Modal from "../../components/UI/Modal";
import LoadingCM from "../../components/UI/Loading";
import {
  getCompanies as getCompaniesRequest,
  getRelatedCars,
} from "../../api/requests";

function Carslist() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [cars, setCars] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showCarModal, setShowCarModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const getCompanies = async () => {
    const res = await getCompaniesRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    setCompanies(res.data);
    setFilteredCompanies(res.data);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const getCar = async (id) => {
    setIsLoading(true);
    const res = await getRelatedCars(id);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    const carsContent = res.data.map((items) => (
      <div
        className={styles.cars}
        key={items._id}
        onClick={() => {
          history.push(`/more/cars/${items._id}`);
          handleClose("car");
        }}
      >
        <span>{items.name}</span>
      </div>
    ));
    setCars(carsContent);
  };
  //car modal
  const handleClose = () => setShowCarModal(false);
  const handleShow = () => setShowCarModal(true);

  //serach
  const handelShowSearch = () => setShowSearch((state) => !state);

  const handelSearchInput = (value) => {
    if (value.trim() === "") {
      return setFilteredCompanies(companies);
    }

    const filterdItems = companies.filter((item) => item.name.match(value));
    setFilteredCompanies(filterdItems);
  };

  let companiesContent = filteredCompanies.map((item) => (
    <div
      style={{ width: "42vw" }}
      key={item._id}
      onClick={() => {
        handleShow("car");
      }}
      className={styles.companyContainer}
    >
      <CompanyCard
        item={item}
        pageName="signUp"
        onClose={handleClose}
        getCar={(id) => getCar(id)}
        reverseColor
      />
    </div>
  ));

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <Fragment>
      {filteredCompanies.length && !isLoading< 1 ? (
        <NotFound title="محصولی یافت نشد" />
      ) : (
        <div className={`${styles.main} ${showSearch ? styles.blur : ""}`}>
          <div className="container-fluid">
            <div className="row">{companiesContent}</div>
          </div>
        </div>
      )}

      <Modal
        show={showCarModal}
        onClose={handleClose}
        title="خودرو ها"
        carModal
      >
        {cars}
      </Modal>

      {!showCarModal && (
        <Search
          handelShow={handelShowSearch}
          showSearch={showSearch}
          onInput={(val) => handelSearchInput(val)}
        />
      )}
    </Fragment>
  );
}

export default Carslist;
