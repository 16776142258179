import React, { useState } from "react";
import parse from 'html-react-parser'

import styles from "./HistoryCard.module.css";
import Modal from "../../UI/Modal";

export default function History({ isModalOpen, item }) {
  const [showModal, setShowModal] = useState(false);
  
  const handleModal = () => {
    setShowModal((prevState) => !prevState);
    //not updated yet
    isModalOpen(!showModal);
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.row}>
        <div>
          <span>تاریخ</span>
          <span>{item?.serviceDate.string.split(" ")[0]}</span>
        </div>

        <div>
          <span>قیمت</span>
          <span> {item?.totalPrice} تومان</span>
        </div>
      </div>

      <div className={styles.row}>
        <div>
          <span>کیلومتر فعلی</span>
          <span>{item?.currentKM}</span>
        </div>

        <div>
          <span>کیلومتر بعدی</span>
          <span>{item?.nextKM}</span>
        </div>
      </div>

      <button className={styles.btn} onClick={handleModal}>
        فاکتور
      </button>

      <Modal show={showModal} onClose={handleModal}>
        {item?.changelog?.gearOil && (
          <div className={styles.modalRow}>
            <div>
              <span>روغن گیربکس</span>
              <span>{item?.changelog?.gearOil.name}</span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.changelog?.gearOil.price} تومان</span>
            </div>
          </div>
        )}

        {item?.changelog?.engineOil && (
          <div className={styles.modalRow}>
            <div>
              <span>روغن موتور</span>
              <span>{item?.changelog?.engineOil.name}</span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.changelog?.engineOil.price} تومان</span>
            </div>
          </div>
        )}

        {item?.changelog?.hydraulicOil && (
          <div className={styles.modalRow}>
            <div>
              <span>روغن فرمان</span>
              <span>{item?.changelog?.hydraulicOil.name}</span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.changelog?.hydraulicOil.price} تومان</span>
            </div>
          </div>
        )}

        {item?.changelog?.fuelFilter && (
          <div className={styles.modalRow}>
            <div>
              <span> فیلتر بنزین</span>
              <span>{item?.changelog?.fuelFilter.name}</span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.changelog?.fuelFilter.price} تومان</span>
            </div>
          </div>
        )}

        {item?.changelog?.oilFilter && (
          <div className={styles.modalRow}>
            <div>
              <span> فیلتر روغن</span>
              <span>{item?.changelog?.oilFilter.name}</span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.changelog?.oilFilter.price} تومان</span>
            </div>
          </div>
        )}

        {item?.changelog?.airFilter && (
          <div className={styles.modalRow}>
            <div>
              <span> فیلتر هوا</span>
              <span>{item?.changelog?.airFilter.name}</span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.changelog?.airFilter.price} تومان</span>
            </div>
          </div>
        )}

        {item?.changelog?.cabinFilter && (
          <div className={styles.modalRow}>
            <div>
              <span> فیلتر اتاق</span>
              <span>{item?.changelog?.cabinFilter.name}</span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.changelog?.cabinFilter.price} تومان</span>
            </div>
          </div>
        )}

        {item?.changelog?.accessories?.length>0 && (
          <div className={styles.modalRow}>
            <div>
              <span> محصولات جانبی</span>
              {item?.changelog?.accessories.map((items, index) => (
                <span className={styles.accessoriesSpan} key={"title" + index}>
                  {items.name}
                </span>
              ))}
            </div>

            <div>
              <span>قیمت</span>
              {item?.changelog?.accessories.map((items, index) => (
                <span className={styles.accessoriesSpan} key={"price" + index}>
                  {items.price} تومان
                </span>
              ))}
            </div>
          </div>
        )}

        {item?.extraCost && (
          <div className={styles.modalRow}>
            <div>
              <span> هزینه اضافی</span>
              <span></span>
            </div>

            <div>
              <span>قیمت</span>
              <span>{item?.extraCost} تومان</span>
            </div>
          </div>
        )}

        {item?.description && (
          <div className={`${styles.modalRow} ${styles.descrition}`}>
            <div>
              <span>توضیحات</span>
              <span className={styles.parsedSpan}>{parse(item?.description)}</span>
            </div>
          </div>
        )}

        <div className={styles.border}></div>

        <div className={`${styles.modalRow} ${styles.totalPrice}`}>
          <div>
            <span>قیمت کل :</span>
            <span>{item?.totalPrice} تومان</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}
