import React, { useContext, useEffect, useState } from "react";
import { FaPhoneAlt, FaUsers, FaUser, FaPencilAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { ReactComponent as OilBottle } from "../../assets/img/oil-bottle.svg";
import { ReactComponent as CarIcon } from "../../assets/img/car-icon.svg";

import styles from "./Home.module.css";
import CustomLink from "../../components/UI/Link";
import Modal from "../../components/UI/Modal";
import { getConfig as getConfigRequest } from "../../api/requests";
import AuthContext from "../../store/auth-context";
import LoadingCM from "../../components/UI/Loading";

const More = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const [showContant, setShowContact] = useState(false);
  const [showAbout, setAboutShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState({ aboutUs: "", contactUs: "" });

  const getData = async () => {
    const res = await getConfigRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    setConfig({
      aboutUs: parse(res.data.aboutUs),
      contactUs: parse(res.data.contactUs),
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClose = (modalName) =>
    modalName === "contact" ? setShowContact(false) : setAboutShow(false);

  const handleShow = (modalName) =>
    modalName === "contact" ? setShowContact(true) : setAboutShow(true);

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div className={styles.container}>
      {isLoggedIn && (
        <CustomLink normal="true" to="/more/profile" className={styles.links}>
          <span>اطلاعات کاربری</span>
          <FaUser size={24} />
        </CustomLink>
      )}

      <CustomLink normal="true" to="/more/products" className={styles.links}>
        <span>محصولات</span>
        <OilBottle fill="white" />
      </CustomLink>

      <CustomLink normal="true" to="/more/cars" className={styles.links}>
        <span>خودرو ها</span>
        <CarIcon fill="white" />
      </CustomLink>

      {isLoggedIn && (
        <CustomLink normal="true" to="/more/notes" className={styles.links}>
          <span>یادداشت ها</span>
          <FaPencilAlt size={20} />
        </CustomLink>
      )}

      <CustomLink
        normal="true"
        to="#"
        className={styles.links}
        onClick={() => handleShow("contact")}
      >
        <span>تماس با ما</span>
        <FaPhoneAlt size={22} />
      </CustomLink>

      <div className={styles.links} onClick={handleShow}>
        <span>درباره ما</span>
        <FaUsers size={24} />
      </div>

      <Modal show={showAbout} onClose={handleClose} title={"درباره ما"}>
        <span className={styles.parsHtml}>{config.aboutUs}</span>
      </Modal>

      <Modal
        show={showContant}
        onClose={() => handleClose("contact")}
        title={"ارتباط با ما"}
      >
        <span className={styles.parsHtml}>{config.contactUs}</span>
      </Modal>
    </div>
  );
};

export default More;
