import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./ProfileCard.module.css";
import { ReactComponent as OilIcon } from "../../../assets/img/oil-bottle.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/delete-icon.svg";
import { IMG_BASE_URL } from "../../../constants/main";

function ProfileCard(props) {
  const carId = props.item._id;
  const history = useHistory();

  return (
    <div className={styles.maintext}>
      <div className={styles.img}>
        <img src={`${IMG_BASE_URL}/${props.item.company?.imageUrl}`} alt="" />
        <div>
          <div className={styles.brand}>{props.item.company?.name}</div>
          <div className={styles.model}>{props.item.name}</div>
        </div>
      </div>

      <div className={styles.detailsConatiner}>
        <div>
          <OilIcon className={styles.svgIcon} />
          <span>نوع روغن موتور</span>
        </div>
        <span>{props.item.engineOilType}</span>
      </div>

      <div className={styles.detailsConatiner}>
        <div>
          <OilIcon className={styles.svgIcon} />
          <span>حجم روغن موتور</span>
        </div>
        <span>{props.item.engineOilVol}</span>
      </div>

      <div className={styles.detailsConatiner}>
        <div>
          <OilIcon className={styles.svgIcon} />
          <span> نوع روغن گیربکس</span>
        </div>
        <span>{props.item.gearBoxOilType}</span>
      </div>

      <div className={styles.detailsConatiner}>
        <div>
          <OilIcon className={styles.svgIcon} />
          <span> حجم روغن گیربکس</span>
        </div>
        <span>{props.item.gearBoxOilVol}</span>
      </div>

      <div className={styles.btndiv}>
        <button
          className={styles.btn}
          onClick={() => history.push(`/more/profile/history/${carId}`)}
        >
          تاریخچه
        </button>
        <button
          className={styles.remove}
          onClick={() => props.openModal(carId)}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  );
}

export default ProfileCard;
