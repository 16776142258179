import React, { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import { FaPhone, FaUserEdit } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import styles from "./Signup.module.css";
import { signUpValidator } from "../../helper/validation";
import NotFound from "../../components/UI/NotFound";
import Search from "../../components/UI/Search";
import {
  getCompanies as getCompaniesRequest,
  getRelatedCars,
  signUp,
  checkCode,
} from "../../api/requests";
import LoadingCM from "../../components/UI/Loading";
import Modal from "../../components/UI/Modal";
import CompanyCard from "../../components/more/car/CompanyCard";
import { IMG_BASE_URL } from "../../constants/main";
import AuthContext from "../../store/auth-context";
import { convertNum } from "../../helper/validation";

export default function Signup() {
  const history = useHistory();
  const authCTX = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const [companies, setCompanies] = useState({});
  const [cars, setCars] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [showCarModal, setShowCarModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [smsCode, setSmsCode] = useState("");
  const [phoneNumber, setPhoneNumebr] = useState("");
  const [selectedCar, setSelectedCar] = useState({
    name: "",
    id: "",
    img: "",
    touched: false,
  });

  const checkSmsCode = async (code) => {
    const values = { phoneNumber: phoneNumber, code: code };
    const res = await checkCode(values);

    if (!res.ok) {
      setSmsCode("");
      return toast.error("کد اشتباه وارد شده", {
        position: "top-center",
      });
    }
    authCTX.login(res.data.token);
    history.replace("/home");
  };

  const getCar = async (id) => {
    handleClose();
    setModalLoading(true);
    const res = await getRelatedCars(id);
    setModalLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    const carsContent = res.data.map((items) => (
      <div
        className={styles.cars}
        key={items._id}
        onClick={() => {
          setSelectedCar({
            name: items.name,
            id: items._id,
            img: items.company.imageUrl,
            touched: false,
          });
          handleClose("car");
        }}
      >
        <span>{items.name}</span>
      </div>
    ));
    setCars(carsContent);
  };

  const handelShowSearch = () => setShowSearch((state) => !state);

  const handelSearchInput = (value) => {
    if (value.trim() === "") {
      return setFilteredCompanies(companies);
    }

    const filterdItems = companies.filter((item) => item.name.match(value));
    setFilteredCompanies(filterdItems);
  };

  //modal
  const handleClose = (modalName) => {
    modalName === "car" ? setShowCarModal(false) : setShowCompanyModal(false);
  };

  const handleShow = (modalName) => {
    modalName === "car" ? setShowCarModal(true) : setShowCompanyModal(true);
  };

  //api call
  const getCompanies = async () => {
    setIsLoading(true);
    const res = await getCompaniesRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setFilteredCompanies(res.data);
    setCompanies(res.data);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  //form
  const handleSubmit = async (values, setSubmitting) => {
    setIsLoading(true);
    values.phoneNumber = convertNum(values.phoneNumber);
    let mergedValues = Object.assign({}, values, { carId: selectedCar.id });
    setPhoneNumebr(values.phoneNumber);

    const res = await signUp(mergedValues);
    setIsLoading(false);

    if (!res.ok) {
      if (res.data?.code === 2) {
        return toast.error("این شماره قبلا ثبت شده است", {
          position: "top-center",
        });
      }
      return toast.error("خطا مجددا نلاش کنید", {
        position: "top-center",
      });
    }
    setShowSmsModal(true);
    setSubmitting(false);
  };

  let companiesContent =
    filteredCompanies.length < 1 ? (
      <NotFound title="محصولی یافت نشد" />
    ) : (
      filteredCompanies.map((item) => (
        <div
          style={{ width: "41vw" }}
          key={item._id}
          onClick={() => {
            handleShow("car");
          }}
        >
          <CompanyCard
            item={item}
            pageName="signUp"
            onClose={handleClose}
            getCar={(id) => getCar(id)}
          />
        </div>
      ))
    );
  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{ phoneNumber: "", name: "" }}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
        validationSchema={signUpValidator}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          setFieldTouched,
          touched,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.formContainer}>
                <div
                  className={`${styles.inputContainer} 
                  ${hideInput ? styles.hideInput : ""}`}
                >
                  <FaUserEdit size={20} className={styles.icon} />
                  <input
                    className={styles.input}
                    name="name"
                    placeholder="نام و نام خانوادگی"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={() => setFieldTouched("name")}
                  />
                </div>
                <p
                  className={`inputErrors ${hideInput ? styles.hideInput : ""}`}
                >
                  {touched.name && errors.name}
                </p>

                <div className={styles.inputContainer}>
                  <FaPhone size={20} className={styles.icon} />
                  <input
                    className={styles.input}
                    name="phoneNumber"
                    placeholder="شماره تلفن همراه"
                    inputMode="numeric"
                    onChange={handleChange}
                    value={values.phoneNumber}
                    onFocus={() => setHideInput(true)}
                    onBlur={() => {
                      setFieldTouched("phoneNumber");
                      setHideInput(false);
                    }}
                  />
                </div>
                <p className="inputErrors">
                  {touched.phoneNumber && errors.phoneNumber}
                </p>

                <div className={styles.inputContainer}>
                  {!selectedCar.name && (
                    <MdAddCircle size={20} className={styles.icon} />
                  )}
                  {selectedCar.name && (
                    <img
                      src={`${IMG_BASE_URL}/${selectedCar.img}`}
                      className={styles.img}
                      alt=""
                    />
                  )}
                  <input
                    className={styles.input}
                    readOnly
                    name="carId"
                    placeholder="خودرو خود را انتخاب کنید"
                    value={selectedCar.name}
                    onClick={handleShow}
                    onBlur={() => {
                      setSelectedCar((prevState) => {
                        let obj = { ...prevState };
                        obj.touched = true;
                        return obj;
                      });
                    }}
                  />
                </div>
                <p className="inputErrors">
                  {!selectedCar.name &&
                    selectedCar.touched &&
                    "ماشین خود را انتخاب کنید"}
                </p>
                <input
                  type="submit"
                  value="ورود"
                  className={styles.submitBtn}
                  disabled={isSubmitting}
                />
              </div>
            </form>
          );
        }}
      </Formik>

      <Modal
        show={showCarModal}
        onClose={() => {
          handleClose("car");
        }}
        title="خودرو ها"
        carModal
      >
        {modalLoading&&<LoadingCM type="spinningBubbles" isModal/>}
        {!modalLoading&&cars}
      </Modal>

      <Modal
        show={showCompanyModal}
        onClose={handleClose}
        title="شرکت سازنده"
        companyModal
      >
        {companiesContent}
        {showCompanyModal && (
          <Search
            inModal
            handelShow={handelShowSearch}
            showSearch={showSearch}
            onInput={(val) => handelSearchInput(val)}
          />
        )}
      </Modal>

      <Modal
        show={showSmsModal}
        title=""
        sms
        onClose={() => setShowSmsModal(false)}
      >
        <p className={styles.smsText}>
          کد تایید به شماره {phoneNumber} ارسال شد
        </p>
        <input
          className={styles.smsInput}
          name="sms"
          inputMode="numeric"
          placeholder="کد را وارد کنید"
          onChange={(e) => {
            if (e.target.value.length === 5) {
              checkSmsCode(e.target.value);
            }
            setSmsCode(e.target.value);
          }}
          value={smsCode}
        />
      </Modal>
    </div>
  );
}
