import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import styles from "./CarDetail.module.css";
import Product from "../../components/more/car/Product";
import { ReactComponent as OilIcon } from "../../assets/img/oil-bottle.svg";
import LoadingCM from "../../components/UI/Loading";
import { getCar as getCarRequest } from "../../api/requests";
import { IMG_BASE_URL } from "../../constants/main";
import CustomLink from "../../components/UI/Link";

function CarDetail() {
  const params = useParams();
  const [car, setCar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recommendedProducts, setRecommendedProducts] = useState([]);

  const getCar = async () => {
    setIsLoading(true);
    const res = await getCarRequest(params.carId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setCar(res.data);
    setRecommendedProducts(res.data?.recommendedOils);
  };

  useEffect(() => {
    getCar();
  }, []);

  const recommendedProductsContent = recommendedProducts.map((item) => (
    <CustomLink
      normal
      className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-6"
      key={item._id}
      to={`/more/products/${item._id}`}
    >
      <Product item={item} />
    </CustomLink>
  ));

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div className={styles.main}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" style={{ padding: "0px" }}>
            <div className={styles.maintext}>
              <div className={styles.img}>
                <img src={`${IMG_BASE_URL}/${car.company?.imageUrl}`} alt="" />
                <div>
                  <div className={styles.brand}>{car.company?.name}</div>
                  <div className={styles.model}>{car.name}</div>
                </div>
              </div>
              <div className={styles.text}>
                <div>
                  <OilIcon className={styles.svgIcon} />
                  نوع روغن موتور
                </div>
                <div>{car.engineOilType}</div>
              </div>
              <div className={styles.text}>
                <div>
                  <OilIcon className={styles.svgIcon} />
                  حجم روغن موتور
                </div>
                <div>{car.engineOilVol}</div>
              </div>
              <div className={styles.text}>
                <div>
                  <OilIcon className={styles.svgIcon} />
                  نوع روغن گیربکس
                </div>
                <div>{car.gearBoxOilType}</div>
              </div>
              <div className={styles.text}>
                <div>
                  <OilIcon className={styles.svgIcon} />
                  حجم روغن گیربکس
                </div>
                <div>{car.gearBoxOilVol}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.titleproduct}>محصولات پیشنهادی</div>
        {recommendedProductsContent.length > 0 && (
          <div className={styles.mainproduct}>
            <div className={styles.products}>{recommendedProductsContent}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CarDetail;
