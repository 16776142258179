import React, { useState, Fragment, useEffect } from "react";
import { useRouteMatch } from "react-router";
import { toast } from "react-toastify";

import styles from "./NewsList.module.css";
import NewsCard from "../../components/news/NewsCard";
import NotFound from "../../components/UI/NotFound";
import CustomLink from "../../components/UI/Link";
import Search from "../../components/UI/Search";
import LoadingCM from "../../components/UI/Loading";
import { getAllNews as getNewsRequest } from "../../api/requests";

function Newslist() {
  const { url } = useRouteMatch();
  const [news, setNews] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredNews, setFilteredNews] = useState([]);

  const getNews = async () => {
    const res = await getNewsRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setFilteredNews(res.data);
    setNews(res.data);
  };

  useEffect(() => {
    getNews();
  }, []);

  const handelShowSearch = () => setShowSearch((state) => !state);

  const handelSearchInput = (value) => {
    if (value.trim() === "") return setFilteredNews(news);

    const filteredItems = news.filter((item) => item.title.match(value));
    setFilteredNews(filteredItems);
  };

  const content = filteredNews.map((item) => (
    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" key={item._id}>
      <div className={styles.newslink}>
        <CustomLink normal to={`${url}/${item._id}`}>
          <NewsCard item={item} />
        </CustomLink>
      </div>
    </div>
  ));

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <Fragment>
      {filteredNews.length < 1 && !isLoading ? (
        <NotFound title="خبر یافت نشد" />
      ) : (
        <div className={`${styles.main} ${showSearch ? styles.blur : ""}`}>
          <div className="container-fluid">
            <div className="row">{content}</div>
          </div>
        </div>
      )}
      <Search
        handelShow={handelShowSearch}
        showSearch={showSearch}
        darkBackDrop
        onInput={(val) => handelSearchInput(val)}
      />
    </Fragment>
  );
}

export default Newslist;
