import React from "react";
import styles from "./Product.module.css";
import { IMG_BASE_URL } from "../../../constants/main";

function Product(props) {
  return (
    <div className={styles.card}>
      <div className={styles.imgnews}>
        <img src={`${IMG_BASE_URL}/${props.item.imageUrl}`} alt="" />
      </div>
      <div className={styles.imgtitle}>${props.item.name}</div>
    </div>
  );
}

export default Product;
