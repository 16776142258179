import React from 'react';
import parse from 'html-react-parser'
import { MdReadMore } from "react-icons/md";

import styles from './NewsCard.module.css';
import {IMG_BASE_URL} from '../../constants/main';
function NewsCard(props) {
    
    return (       
        <div className={styles.card}>
            <div className={styles.imgnews}>
                <img src={`${IMG_BASE_URL}/${props.item.imageUrl}`} alt="" />
            </div>
            <div className={styles.imgtitle}>
                {props.item.title}
            </div>
            <div className={styles.imgtext}>
               {parse(props.item.content)}
            </div>
            <div className={styles.time}>
                <span>{props.item.createdAt?.description}</span>
            </div>
            <MdReadMore  className={styles.detailIcon}/>
        </div>
    );
}

export default NewsCard;