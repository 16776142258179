import * as Yup from "yup";

export const signUpValidator = Yup.object().shape({
    phoneNumber: Yup.string()
        .required(" شماره تلفن خود را وارد کنید ")
        .matches(/^[\u06F0-\u06F90-9]+$/, "تفلن همراه نامعتبر")
        .min(11, "شماره تلفن همراه باید ۱۱ رقم باشد")
        .max(11, "شماره تلفن همراه باید ۱۱ رقم باشد")
        .label(" تلفن "),
    name: Yup.string().required("نام خود را وارد کنید")
});

export const loginValidator = Yup.object().shape({
    phoneNumber: Yup.string()
        .required(" شماره تلفن خود را وارد کنید ")
        .matches(/^[\u06F0-\u06F90-9]+$/, "تفلن همراه نامعتبر")
        .min(11, "شماره تلفن همراه باید ۱۱ رقم باشد")
        .max(11, "شماره تلفن همراه باید ۱۱ رقم باشد")
        .label(" تلفن "),
});

let
    persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

export const convertNum = (str ) => {
    if (typeof str === 'string') {
        for (var i = 0; i < 10; i++) {
            str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
    }
    return str;
};
