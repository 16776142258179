import React, { useContext } from "react";
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Services from "./services/Home";
import NewsList from "./news/NewsList";
import NewsDetail from "./news/NewsDetail";
import More from "./more/Home";
import Cars from "./more/CarsList";
import CarDetail from "./more/CarDetail";
import Products from "./more/ProductList";
import ProductDetail from "./more/ProductDetail";
import WebHome from "./WebHome";
import BottomTabBar from "../components/UI/BottomTabBar";
import Header from "../components/UI/Header";
import Login from "./services/Login";
import Signup from "./services/Signup";
import Profile from "./more/Profile";
import History from "./more/History";
import Notes from "./more/NoteList";
import AddNote from "./more/AddNote";
import NoteDetail from "./more/NoteDetail";
import AuthContext from "../store/auth-context";

const Index = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <HashRouter>
      <ToastContainer rtl />
      <Switch>
        <Route path="/" exact>
          <WebHome />
        </Route>
        <Route path="/home" exact>
          <Header title="سرویس ها" />
          <Services />
          <BottomTabBar />
        </Route>
        {!isLoggedIn && (
          <Route path="/home/login">
            <Header title="ورود" backBtn />
            <Login />
            <BottomTabBar />
          </Route>
        )}
        {!isLoggedIn && (
          <Route path="/home/signup">
            <Header title="ثبت نام" backBtn />
            <Signup />
            <BottomTabBar />
          </Route>
        )}
        <Route path="/more" exact>
          <Header title="بیشتر" />
          <More />
          <BottomTabBar />
        </Route>
        {isLoggedIn && (
          <Route path="/more/profile" exact>
            <Header title="اطلاعات کاربری" backBtn />
            <Profile />
            <BottomTabBar />
          </Route>
        )}
        {isLoggedIn && (
          <Route path="/more/profile/history/:carId" exact>
            <Header title="تاریخچه" backBtn />
            <History />
            <BottomTabBar />
          </Route>
        )}
        <Route path="/more/cars" exact>
          <Header title="خودرو ها" backBtn />
          <Cars />
          <BottomTabBar />
        </Route>
        <Route path="/more/cars/:carId">
          <Header title="خودرو" backBtn />
          <CarDetail />
          <BottomTabBar />
        </Route>
        <Route path="/more/products" exact>
          <Header title="محصولات" backBtn />
          <Products />
          <BottomTabBar />
        </Route>
        <Route path="/more/products/:productId">
          <Header title="اطلاعات محصول" backBtn />
          <ProductDetail />
          <BottomTabBar />
        </Route>
        {isLoggedIn && (
          <Route path="/more/notes" exact>
            <Header title="یادداشت ها" backBtn />
            <Notes />
            <BottomTabBar />
          </Route>
        )}
        {isLoggedIn && (
          <Route path="/more/notes/add" exact>
            <Header title="اضافه کردن یادداشت" backBtn />
            <AddNote />
            <BottomTabBar />
          </Route>
        )}
        {isLoggedIn && (
          <Route path="/more/notes/:noteId">
            <Header title="یادداشت" backBtn />
            <NoteDetail />
            <BottomTabBar />
          </Route>
        )}
        <Route path="/news" exact>
          <Header title="اخبار" />
          <NewsList />
          <BottomTabBar />
        </Route>
        <Route path="/news/:newsId">
          <Header title="خبر" backBtn />
          <NewsDetail />
          <BottomTabBar />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </HashRouter>
  );
};

export default Index;
