import React, { useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";

import styles from "./AddNote.module.css";
import LoadingCM from "../../components/UI/Loading";
import {
  updateNote as updateNoteRequest,
  getNote as getNoteRequest,
} from "../../api/requests";

export default function AddNote() {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [titleFoucsed, setTitleFoucsed] = useState(false);
  const [contentFoucsed, setContentFoucsed] = useState(false);

  const focusHandler = (name) => {
    if (name == "title") setTitleFoucsed(true);
    else setContentFoucsed(true);
  };
  const blurHandler = (name) => {
    if (name == "title") setTitleFoucsed(false);
    else setContentFoucsed(false);
  };

  const getNote = async () => {
    setIsLoading(true);
    const res = await getNoteRequest(params.noteId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    setTitle(res.data.title);
    setContent(res.data.content);
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    const values = { title: title, content: content };

    if (values.title.trim() === "" || values.content.trim() === "") {
      return toast.warn("موضوع یا متن نباید خالی باشد", {
        position: "top-center",
      });
    }

    setIsLoading(true);
    const res = await updateNoteRequest(params.noteId,values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    toast.success(" یادداشت با موفقیت ثبت شد", {
      position: "top-center",
      autoClose: 2000,
    });
    setTimeout(() => history.goBack(), 2000);
  };

  useEffect(() => {
    getNote();
  }, []);

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <form className={styles.inputContainer} onSubmit={handelSubmit}>
      <input
        className={`${styles.title}  
        ${contentFoucsed ? styles.titleResize : ""}`}
        onChange={(e) => setTitle(e.target.value)}
        onFocus={() => focusHandler("title")}
        onBlur={() => blurHandler("title")}
        value={title}
      />
      <textarea
        className={` ${styles.content}
        ${titleFoucsed ? styles.contentResize : ""}`}
        onChange={(e) => setContent(e.target.value)}
        onFocus={focusHandler}
        onBlur={blurHandler}
        value={content}
      />
      <div className={styles.submitBtnConatiner}>
        <button className={styles.submitBtn} type="submit">
          <MdCheck size={22} fill="white" />
        </button>
      </div>
    </form>
  );
}
