import crypto from 'crypto-js';
import { TOKEN_SECRET } from '../constants/main'

export const getToken = () => {
    let encryptedToken = localStorage.getItem('user_token');
    if (!encryptedToken) return undefined

    let bytes = crypto.AES.decrypt(encryptedToken, TOKEN_SECRET);
    let decryptedToken = bytes.toString(crypto.enc.Utf8);
    return decryptedToken
}
export const setToken = (token) => {
    if (token) {
        let encryptedToken = crypto.AES.encrypt(token, TOKEN_SECRET).toString();
        localStorage.setItem("user_token", encryptedToken);
    } else
        throw Error("invalid token")
}

export const removeToken = () => {
    localStorage.removeItem("user_token")
}