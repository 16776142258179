import React from "react";
import styles from "./CompanyCard.module.css";
import { IMG_BASE_URL } from "../../../constants/main";

function CompanyCard(props) {
  return (
    <div className={`${styles.card} ${props.reverseColor?styles.reverseColor:''}`} >
      {props.pageName === "signUp" ? (
        <div
          className={styles.imgnews}
          onClick={() => {
            props.getCar(props.item._id);
          }}
        >
          <img src={`${IMG_BASE_URL}/${props.item.imageUrl}`} alt="" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CompanyCard;
