import React, { useContext, useEffect, useState, Fragment } from "react";
import { FiLogIn } from "react-icons/fi";
import { FaUserPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import Carousel from "react-elastic-carousel";
import { useHistory } from "react-router";

import logo from "../../assets/img/logo-512.png";
import CustomLink from "../../components/UI/Link";
import styles from "./Home.module.css";
import LoadingCM from "../../components/UI/Loading";
import AuthContext from "../../store/auth-context";
import { getLastService as getLastServiceRequest } from "../../api/requests";
import EmptyService from "../../components/services/EmptyService";
import CarServices from "../../components/services/CarServices";
import Splash from "../../components/UI/Splash";

let counter = 0;
export default function Home() {
  const history = useHistory();

  const { isLoggedIn, logout } = useContext(AuthContext);
  const [service, setService] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLastService = async () => {
    if (!isLoggedIn) return setTimeout(() => setIsLoading(false), 500);

    const res = await getLastServiceRequest();
    setIsLoading(false);

    if (!res.ok) {
      if (res.data?.code == 999) {
        toast.error("اکانت شما حذف شده", {
          position: "top-center",
          autoClose: 2000,
        });
        return setTimeout(() => {
          logout();
          history.replace("/home");
        }, 3000);
      }
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setService(res.data);
  };

  useEffect(() => {
    getLastService();
    counter++;
  }, []);

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <Fragment>
      <Splash renderPageCount={counter} />
      <div
        className={`${styles.container} ${isLoggedIn ? styles.fullHeight : ""}`}
      >
        {isLoggedIn &&
          (service.length < 1 && !isLoading ? (
            <EmptyService />
          ) : (
            <Carousel isRTL pagination={false}>
              {service.map((item) => (
                <CarServices item={item} key={item._id} />
              ))}
            </Carousel>
          ))}

        {!isLoggedIn && (
          <>
            <img src={logo} className={styles.img} alt="" />
            <p>از ۱۳۵۵ تا به امروز - فرشید غزالیان</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className={styles.btnContainer}>
                <CustomLink
                  className={styles.login}
                  normal="true"
                  to="home/login"
                >
                  <span>ورود</span>
                  <FiLogIn size={22} style={{ transform: "scaleX(-1)" }} />
                </CustomLink>
                <CustomLink
                  className={styles.signup}
                  normal="true"
                  to="home/signup"
                >
                  <span>ثبت نام</span>
                  <FaUserPlus size={22} />
                </CustomLink>
              </div>
              <p>برای استفاده از سرویس های ما ابتدا باید وارد شوید</p>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
}
