import React, { Fragment, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { toast } from "react-toastify";

import styles from "./ProductList.module.css";
import ProductCard from "../../components/more/product/ProductCard";
import NotFound from "../../components/UI/NotFound";
import CustomLink from "../../components/UI/Link";
import Search from "../../components/UI/Search";
import LoadingCM from "../../components/UI/Loading";
import { getProducts as getProductsRequest } from "../../api/requests";

function Productlist() {
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [showSearch, setShowSearch] = useState(false);

  const getProducts = async () => {
    const res = await getProductsRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setProducts(res.data);
    setFilteredProduct(res.data);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handelShowSearch = () => setShowSearch((state) => !state);

  const handelSearchInput = (value) => {
    if (value.trim() === "") return setFilteredProduct(products);

    const filteredItems = products.filter(
      (item) => item.name.match(value) || item.description.match(value)
    );
    setFilteredProduct(filteredItems);
  };
  const content = filteredProduct.map((item) => (
    <CustomLink
      normal
      className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-6"
      key={item._id}
      to={`${url}/${item._id}`}
    >
      <ProductCard item={item} />
    </CustomLink>
  ));

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <Fragment>
      {filteredProduct.length && !isLoading< 1 ? (
        <NotFound title="محصولی یافت نشد" />
      ) : (
        <div className={`${styles.main} ${showSearch ? styles.blur : ""}`}>
          <div className="container-fluid">
            <div className={styles.container}>{content}</div>
          </div>
        </div>
      )}
      <Search
        handelShow={handelShowSearch}
        showSearch={showSearch}
        darkBackDrop
        onInput={(val) => handelSearchInput(val)}
      />
    </Fragment>
  );
}

export default Productlist;
