import React, { useEffect, useState } from "react";
import { MdArticle, MdViewList } from "react-icons/md";
import { ReactComponent as OilIcon } from "../../assets/img/oil-bottle.svg";
import { useLocation } from "react-router-dom";

import CustomLink from "./Link";
import { ROUTE_NAMES } from "../../constants/main";
import styles from "./BottomTabBar.module.css";

export default function BottomTabBar() {
  const { pathname } = useLocation();
  const [, currentPath] = pathname.split("/");
  const currentPage = ROUTE_NAMES[currentPath];
  const [title, setTitle] = useState(currentPage);

  useEffect(() => {
    setTitle(currentPage);
  }, [currentPage]);

  return (
    <section className={styles.container}>
      <div>
        <CustomLink onClick={() => setTitle("اخبار")} to="/news">
          <MdArticle
            className={`${styles.icons} ${
              title === "اخبار" && styles.iconsActive
            }`}
            size={24}
          />
          {title === "اخبار" && <p className={styles.iconText}>{title}</p>}
        </CustomLink>
      </div>
      <div>
        <CustomLink to="/home" onClick={() => setTitle("سرویس ها")}>
          <OilIcon
            className={`${styles.svgIcon} ${
              title === "سرویس ها" && styles.svgIconActive
            }`}
          />
          {title === "سرویس ها" && <p className={styles.iconText}>{title}</p>}
        </CustomLink>
      </div>
      <div>
        <CustomLink
          to="/more"
          onClick={() => setTitle("بیشتر")}
          style={{ color: "white" }}
        >
          <MdViewList
            className={`${styles.icons} ${
              title === "بیشتر" && styles.iconsActive
            }`}
            size={24}
          />
          {title === "بیشتر" && <p className={styles.iconText}>{title}</p>}
        </CustomLink>
      </div>
    </section>
  );
}
