import React from "react";
import { ReactComponent as OilIcon } from "../../assets/img/oil-bottle.svg";

import styles from "./EmptyService.module.css";

const Services = () => {
  return (
    <div className={styles.container}>
      <OilIcon className={styles.icon}  />

      <div className={styles.textContainer}>
        <p> شما تا کنون سرویسی انجام نداده اید</p>
        <p>
          در صورت انجام سرویس , وضعیت اخرین سرویس انجام شده در این صفحه قابل
          مشاهده خواهد بود
        </p>
      </div>
    </div>
  );
};

export default Services;
