import React from "react";
import { Link } from "react-router-dom";

import styles from "./Link.module.css";

export default function CustomLink(props) {
  const output =
    props.normal === "true" ? (
      <Link style={{ textDecoration: "none" }} {...props}>
        {props.children}
      </Link>
    ) : (
      <Link to={props.to} onClick={props.onClick} className={styles.container}>
        {props.children}
      </Link>
    );

  return output;
}
