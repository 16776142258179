import { createContext, useState } from 'react';
import {
    getToken as getTokenFromStorage,
    setToken as setTokenInStorage,
    removeToken as removeTokenFromStorage
} from '../helper/storage'

const AuthContext = createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => { },
    logout: () => { },
})

export function AuthContextProvider(props) {
    const initialToken = getTokenFromStorage();
    const [token, setToken] = useState(initialToken);

    const isLoggedIn = !!token;

    const loginHandler = (userToken) => {
        setToken(userToken)
        setTokenInStorage(userToken)
    };
    const logoutHandler = () => {
        setToken(null);
        removeTokenFromStorage();
    };

    const context = {
        token: token,
        isLoggedIn:isLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    };

    return <AuthContext.Provider value={context}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext;