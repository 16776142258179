import React from "react";
import { AiFillClockCircle, AiFillDashboard } from "react-icons/ai";
import {
  MdOutlineFilterDrama,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { RiOilFill } from "react-icons/ri";

import styles from "./CarServices.module.css";
import { ReactComponent as OilIcon } from "../../assets/img/oil-bottle.svg";
import { ReactComponent as CarIcon } from "../../assets/img/car-icon.svg";
import { IMG_BASE_URL } from "../../constants/main";

export default function CarServices(props) {
  return (
    <div className={styles.main}>
      <div className={styles.imageConatiner}>
        <img
          src={`${IMG_BASE_URL}/${props.item?.car.company.imageUrl}`}
          alt=""
        />
        <p>{props.item?.car.name}</p>
      </div>

      <div className={styles.ServicesContainer}>
        <div className={styles.card}>
          <div className={styles.cardLogo}>
            <AiFillDashboard />
          </div>
          <p>کیلومتر فعلی</p>
          <p>{props.item?.currentKM}</p>
        </div>

        <div className={styles.card}>
          <div className={styles.cardLogo}>
            <AiFillDashboard />
          </div>
          <p>کیلومتر بعدی</p>
          <p>{props.item?.nextKM}</p>
        </div>

        <div className={styles.card}>
          <div className={styles.cardLogo}>
            <AiFillClockCircle />
          </div>
          <p>تاریخ تعویض</p>
          <p>{props.item?.serviceDate?.string.split(" ")[0]}</p>
        </div>

        {props.item?.changelog?.engineOil && (
          <div className={styles.card}>
            <div className={styles.cardLogo}>
              <OilIcon />
            </div>
            <p>روغن موتور</p>
            <p>{props.item?.changelog?.engineOil.name}</p>
          </div>
        )}

        {props.item?.changelog?.oilFilter && (
          <div className={styles.card}>
            <div className={styles.cardLogo}>
              <OilIcon />
            </div>
            <p>فیلتر روغن</p>
            <p>{props.item?.changelog?.oilFilter?.name}</p>
          </div>
        )}
        {props.item?.changelog?.gearOil && (
          <div className={styles.card}>
            <div className={styles.cardLogo}>
              <OilIcon />
            </div>
            <p>روغن گیربکس</p>
            <p>{props.item?.changelog?.gearOil.name}</p>
          </div>
        )}

        {props.item?.changelog?.fuelFilter && (
          <div className={styles.card}>
            <div className={styles.mdCardLogo}>
              <RiOilFill style={{ fill: "#3bb699", width: 20, height: 20 }} />
            </div>
            <p>فیلتر بنزین</p>
            <p>{props.item?.changelog?.fuelFilter?.name}</p>
          </div>
        )}

        {props.item?.changelog?.cabinFilter && (
          <div className={styles.card}>
            <div className={styles.cardLogo}>
              <CarIcon />
            </div>
            <p>فیلتر اتاق</p>
            <p>{props.item?.changelog?.cabinFilter?.name}</p>
          </div>
        )}

        {props.item?.changelog?.airFilter && (
          <div className={styles.card}>
            <div className={styles.mdCardLogo}>
              <MdOutlineFilterDrama
                style={{ fill: "#3bb699", width: 20, height: 20 }}
              />
            </div>
            <p>فیلتر هوا</p>
            <p>{props.item?.changelog?.airFilter?.name}</p>
          </div>
        )}

        {props.item?.changelog?.accessories?.length > 0 && (
          <div className={styles.card}>
            <div className={styles.mdCardLogo}>
              <MdProductionQuantityLimits
                style={{ fill: "#3bb699", width: 20, height: 20 }}
              />
            </div>
            <p>محصولات جانبی</p>
            {props.item?.changelog?.accessories?.map((items, index) => (
              <p
                style={{ marginTop: 5, direction: "rtl" }}
                key={items._id + index}
              >
                {index + 1} - {items.name}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
