import React from "react";
import { useHistory } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

import styles from "./Header.module.css";

export default function Header(props) {
  const { goBack } = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {props.backBtn && (
          <div className={styles.backIcon} onClick={goBack}>
            {" "}
            <MdKeyboardArrowRight size={34} />
          </div>
        )}
        <span style={{ color: "white" }}>{props.title}</span>
      </div>
    </div>
  );
}
