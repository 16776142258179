import React,{useState,useEffect} from "react";
import { useParams } from "react-router";
import parse from "html-react-parser";
import { toast } from "react-toastify";

import styles from "./NewsDetail.module.css";
import LoadingCM from "../../components/UI/Loading";
import { getNews as getNewsRequest } from "../../api/requests";
import { IMG_BASE_URL } from "../../constants/main";

function NewsDetail() {
  const params = useParams();
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newsContent,setNewsContent]=useState('');

  const getNews = async () => {
    setIsLoading(true);
    const res = await getNewsRequest(params.newsId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setNews(res.data);
    const content=parse(res.data.content);
    setNewsContent(content);

  };

  useEffect(() => {
    getNews();
  }, []);

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div className={styles.main}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={styles.img}>
              <img src={`${IMG_BASE_URL}/${news.imageUrl}`} alt="" />
            </div>
          </div>
          <div className="col-12">
            <p className={styles.title}>{news.title}</p>
          </div>
          <div className={`col-12 ${styles.content}`}>
           {newsContent}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;
