import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import styles from "./History.module.css";
import HistoryCard from "../../components/more/profile/HistoryCard";
import LoadingCM from "../../components/UI/Loading";
import { getHistoryById } from "../../api/requests";
import Search from "../../components/UI/Search";
import NotFound from "../../components/UI/NotFound";

export default function History() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getHistory = async () => {
    setIsLoading(true);
    const res = await getHistoryById(params.carId);
    setIsLoading(false);
    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setHistory(res.data);
    setFilteredHistory(res.data);
  };

  const handelShowSearch = () => setShowSearch((state) => !state);

  const handelSearchInput = (value) => {
    if (value.trim() === "") {
      return setFilteredHistory(history);
    }

    const filterdItems = history.filter((items) =>
      items.changedItems?.match(value)
    );
    setFilteredHistory(filterdItems);
  };
  useEffect(() => {
    getHistory();
  }, []);

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <Fragment>
      <div className={styles.cardContainer}>
        {filteredHistory.length ? (
          filteredHistory.map((item) => (
            <HistoryCard
              item={item}
              key={item?._id}
              isModalOpen={setIsModalOpen}
            />
          ))
        ) : (
          <NotFound title="سرویسی یافت نشد" />
        )}
      </div>

      {!isModalOpen && (
        <Search
          darkBackDrop
          reverseColor
          handelShow={handelShowSearch}
          showSearch={showSearch}
          onInput={(val) => handelSearchInput(val)}
        />
      )}
    </Fragment>
  );
}
