import React from "react";
import { MdDelete, MdReadMore } from "react-icons/md";

import styles from "./NoteCard.module.css";

function NoteCard(props) {
  return (
    <div className={styles.cardContainer}  onClick={() => props.onClick(props.item._id)}>
      <div className={styles.row1}>
        <span>{props.item.title}</span>
        <span>{props.item.createdAt?.description}</span>
      </div>

      <div className={styles.row2}>
        <span>{props.item.content}</span>
      </div>

      <div className={styles.row3}>
        <div className={styles.iconWraper}>
          <MdDelete
            onClick={(e) => {
              e.stopPropagation();
              props.onDetele(props.item._id);
            }}
          />
          <MdReadMore
            className={styles.moreIcon}
            onClick={() => props.onClick(props.item._id)}
          />
        </div>
      </div>
    </div>
  );
}

export default NoteCard;
