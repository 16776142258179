import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import styles from "./NoteList.module.css";
import NoteCard from "../../components/more/NoteCard";
import {
  getAllNotes,
  deleteNote as deleteNoteRequest,
} from "../../api/requests";
import LoadingCM from "../../components/UI/Loading";
import CustomLink from "../../components/UI/Link";
import NotFound from "../../components/UI/NotFound";
import Search from "../../components/UI/Search";
import DeleteModal from "../../components/UI/DeleteModal";

export default function NoteList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handelDeleteModal = (id) => {
    if (id) setNoteId(id);
    setShowDeleteModal((state) => !state);
  };

  const deleteNote = async (id) => {
    setIsLoading(true);
    const res = await deleteNoteRequest(id);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    getNotes();
  };

  const getNotes = async () => {
    const res = await getAllNotes();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setNotes(res.data);
    setFilteredNotes(res.data);
  };

  const handelShowSearch = () => setShowSearch((state) => !state);

  const handelSearchInput = (value) => {
    if (value.trim() === "") return setFilteredNotes(notes);

    const filteredItems = notes.filter(
      (item) => item.title.match(value) || item.content.match(value)
    );
    setFilteredNotes(filteredItems);
  };
  const navigateNoteDetail = (id) => {
    history.push(`/more/notes/${id}`);
  };

  const content = filteredNotes.map((item) => (
    <NoteCard
      item={item}
      key={item._id}
      onDetele={(id) => handelDeleteModal(id)}
      onClick={(id) => {
        navigateNoteDetail(id);
      }}
    />
  ));

  useEffect(() => {
    getNotes();
  }, []);

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div>
      {filteredNotes.length ? (
        <div className={styles.container}>{content}</div>
      ) : (
        <NotFound title="یادداشتی پیدا نشد" />
      )}

      <DeleteModal
        show={showDeleteModal}
        onClose={handelDeleteModal}
        onDelete={() => {
          deleteNote(noteId);
          handelDeleteModal();
        }}
      />

      <Search
        handelShow={handelShowSearch}
        showSearch={showSearch}
        darkBackDrop
        onInput={(val) => handelSearchInput(val)}
      />

      {!showSearch && (
        <div className={styles.addWrapper}>
          <CustomLink
            normal="true"
            to="/more/notes/add"
            className={styles.addContainer}
          >
            <MdAdd size={22} fill="white" style={{ marginTop: 15 }} />
          </CustomLink>
        </div>
      )}
    </div>
  );
}
