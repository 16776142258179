import React from "react";
import { MdClose } from "react-icons/md";
import { createPortal } from "react-dom";

import styles from "./Modal.module.css";

function Modal(props) {
  return createPortal(
    <>
      <div
        className={`${styles["modalContainer"]}
         ${props.show ? styles.show : ""}`}
        onClick={props.onClose}
      >
        <div
          className={`
           ${styles.modal}
           ${props.sms ? styles.sms : ""}
           ${props.carModal ? styles.noAnim : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          {!props.sms && (
            <div className={styles.head}>
              <MdClose fill="white" size={28} onClick={props.onClose} />
              {props.title}
            </div>
          )}

          <div
            className={`
                ${styles.content}
                ${props.sms ? styles.smsContainer : ""}      
                ${props.companyModal ? styles.companyModal : ""}`}
          >
            {props.children}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modal")
  );
}

export default Modal;
