import React from "react";
import { BsExclamation } from "react-icons/bs";
import styles from "./NotFound.module.css";

export default function NotFound(props) {
  return (
    <div className={styles.container}>
      <BsExclamation className={styles.icon} size={42}/>
      <span> {props.title}</span>
    </div>
  );
}
