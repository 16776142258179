import React, { useState } from "react";
import { MdSearch } from "react-icons/md";

import styles from "./Search.module.css";

export default function Search(props) {
  const [isShown, setIsShown] = useState(false);
  console.log();
  return (
    <div
      className={`
       ${styles.searchWrapper} 
       ${props.inModal ? styles.inModalBackdrop : ""}
       ${isShown ? styles.activePointer : ""} 
      `}
    >
      <div
        onClick={() => {
          props.handelShow();
          setIsShown((state) => !state);
        }}
        className={`
         ${styles.backDrop}
         ${props.showSearch && styles.show}
         ${props.inModal && styles.inModal}
         ${props.darkBackDrop && styles.darkBackDrop}
         `}
      ></div>

      <div
        className={styles.searchContainer}
        onClick={() => {
          props.handelShow();
          setIsShown((state) => !state);
        }}
      >
        <MdSearch className={styles.searchIcon} size={20} />
      </div>

      <input
        className={`${styles.searchInput} ${
          props.showSearch ? styles.show : ""
        }`}
        placeholder="جستجو کنید..."
        onChange={(e) => props.onInput(e.target.value)}
      />
    </div>
  );
}
