import { create } from 'apisauce';
import { PUBLIC_TOKEN } from '../constants/main';
import { getToken } from '../helper/storage';

export const apiClient = create({
    baseURL: 'https://api.autoservicefarshid.ir/v1',
})

apiClient.addAsyncRequestTransform(async(request) => {
    let authToken = await getToken();
    if (!authToken) authToken = PUBLIC_TOKEN;

    request.headers["authorization"] = "Bearer " + authToken;

})
export default apiClient;