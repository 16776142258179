import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import styles from "./ProductDetail.module.css";
import { getProduct as getProductRequest } from "../../api/requests";
import LoadingCM from "../../components/UI/Loading";
import { IMG_BASE_URL } from "../../constants/main";

function ProductDetail() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState([]);

  const getProduct = async () => {
    setIsLoading(true);
    const res = await getProductRequest(params.productId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setProduct(res.data);
  };

  useEffect(() => {
    getProduct();
  }, []);

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div className={styles.main}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <p className={styles.title}>{product.name}</p>
          </div>

          <div className="col-12" style={{ padding: "0px" }}>
            <div className={styles.maintext}>
              <div className={styles.img}>
                <img src={`${IMG_BASE_URL}/${product.imageUrl}`} alt="" />
              </div>
              <p className={styles.text}>{product.content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
