import React, { Fragment, useContext, useState, useEffect } from "react";
import { AiOutlinePoweroff } from "react-icons/ai";
import { toast } from "react-toastify";
import { FaTimes, FaCheck } from "react-icons/fa";

import styles from "./Profile.module.css";
import {
  getUserInfo as userInfoRequest,
  deleteUserCar as deleteCarRequest,
  addUserCar as addCarRequest,
  getCompanies as getCompaniesRequest,
  getRelatedCars,
} from "../../api/requests";
import plus from "../../assets/img/plus.png";
import ProfileCard from "../../components/more/profile/ProfileCard";
import LoadingCM from "../../components/UI/Loading";
import AuthContext from "../../store/auth-context";
import CompanyCard from "../../components/more/car/CompanyCard";
import Modal from "../../components/UI/Modal";
import NotFound from "../../components/UI/NotFound";
import Search from "../../components/UI/Search";
import DeleteModal from "../../components/UI/DeleteModal";

function Profile() {
  const { logout } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [companies, setCompanies] = useState({});
  const [cars, setCars] = useState(null);
  const [carId, setCarId] = useState(null);
  const [showCarModal, setShowCarModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const handelShowSearch = () => setShowSearch((state) => !state);

  const handelDeleteModal = (id) => {
    if (id) setCarId(id);
    setShowDeleteModal((state) => !state);
  };

  const deleteCar = async (id) => {
    setIsLoading(true);
    const res = await deleteCarRequest(id);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    toast.success("حذف با موفقیت انجام شد", {
      position: "top-center",
    });
    getUserInfo();
  };

  const getUserInfo = async () => {
    const res = await userInfoRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setUserInfo(res.data);
  };

  const getCar = async (id) => {
    handleClose();
    setModalLoading(true);
    const res = await getRelatedCars(id);
    setModalLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    const carsContent = res.data.map((items) => (
      <div
        className={styles.cars}
        key={items._id}
        onClick={() => {
          handleClose("car");
          addCar(items._id);
        }}
      >
        <span>{items.name}</span>
      </div>
    ));
    setCars(carsContent);
  };

  //modal
  const handleClose = (modalName) => {
    modalName === "car" ? setShowCarModal(false) : setShowCompanyModal(false);
  };

  const handleShow = (modalName) => {
    modalName === "car" ? setShowCarModal(true) : setShowCompanyModal(true);
  };

  //api call
  const getCompanies = async () => {
    const res = await getCompaniesRequest();

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    setFilteredCompanies(res.data);
    setCompanies(res.data);
  };

  const addCar = async (id) => {
    setIsLoading(true);
    const res = await addCarRequest(id);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    getUserInfo();
  };

  const handelSearchInput = (value) => {
    if (value.trim() === "") {
      return setFilteredCompanies(companies);
    }

    const filterdItems = companies.filter((item) => item.name.match(value));
    setFilteredCompanies(filterdItems);
  };

  useEffect(() => {
    getUserInfo();
    getCompanies();
  }, []);

  let companiesContent =
    filteredCompanies.length < 1 ? (
      <NotFound title="محصولی یافت نشد" />
    ) : (
      filteredCompanies.map((item) => (
        <div
          style={{ width: "41vw" }}
          key={item._id}
          onClick={() => {
            handleShow("car");
          }}
        >
          <CompanyCard
            item={item}
            pageName="signUp"
            onClose={handleClose}
            getCar={(id) => getCar(id)}
          />
        </div>
      ))
    );

  const content = userInfo.cars?.map((item) => (
    <ProfileCard key={item._id} item={item} openModal={handelDeleteModal} />
  ));

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <Fragment>
      <div className={styles.ptitle}>
        <h2>#{userInfo.cid}</h2>

        <p className={styles.phone}>{userInfo.phoneNumber}</p>
        <div className={styles.thirdRowContainer}>
          <div className={styles.logoutContainer} onClick={logout}>
            <AiOutlinePoweroff size={20} />
          </div>
          <span className={styles.name}>{userInfo.name}</span>
        </div>
      </div>

      <div className={styles.main}>
        <div className={styles.scroll}>
          {content}

          <div 
            className={styles.mainaddcar}
            onClick={() => setShowCompanyModal(true)}
          >
            <div className={styles.plus} >
              <img src={plus} alt="" />
            </div>
            <div>ماشین خود را اضافه کنید</div>
          </div>
        </div>
      </div>

      {userInfo.clubMember && (
        <div className={styles.club}>
          <span className={styles.isMember}>
            <FaCheck /> عضو باشگاه مشتریان
          </span>
        </div>
      )}

      <Modal
        show={showCarModal}
        onClose={() => {
          handleClose("car");
        }}
        title="خودرو ها"
        carModal
      >
        {modalLoading && <LoadingCM type="spinningBubbles" isModal />}
        {!modalLoading && cars}
      </Modal>

      <Modal
        show={showCompanyModal}
        onClose={handleClose}
        title="شرکت سازنده"
        companyModal
      >
        {companiesContent}
        {showCompanyModal && (
          <Search
            inModal
            handelShow={handelShowSearch}
            showSearch={showSearch}
            onInput={(val) => handelSearchInput(val)}
          />
        )}
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        onClose={handelDeleteModal}
        onDelete={() => {
          deleteCar(carId);
          handelDeleteModal();
        }}
      />
    </Fragment>
  );
}

export default Profile;
